import { arrayContainsAny } from './arrayHelpers'

const amenitiesWithIcons = [
  {
    id: 'tricon-difference',
    icon: 'emblem',
    amenities: ['Tricon Difference'],
    name: 'Tricon Difference',
    link: '/tricon-difference/',
    sort: 1
  },
  {
    id: 'smart-home',
    icon: 'smart-home',
    amenities: ['Smart Home'],
    filterLabel: 'Smart Home',
    name: 'Smart Home',
    link: '/resources/smarthome/',
    sort: 2
  },
  {
    id: 'professionally-maintained',
    icon: 'handtool',
    amenities: ['Professionally Maintained'],
    name: 'Professionally Maintained',
    link: '/resources/maintained/',
    sort: 3
  },
  {
    id: 'pets',
    icon: 'pets',
    amenities: ['Pet Friendly'],
    filterLabel: 'Pet Friendly',
    name: 'Pet Friendly',
    link: '/resources/pet-friendly-us/',
    sort: 4
  },
  {
    id: 'solar',
    icon: 'solar',
    amenities: ['Solar Home', 'Solar Home (SFRC)'],
    filterLabel: 'Solar Home',
    name: 'Solar Home',
    link: '/resources/solar-home-us/',
    filterKey: 'solar',
    sort: 10
  },
  {
    id: 'deposit-waiver',
    icon: 'handcash',
    amenities: ['Deposit Waiver'],
    filterLabel: 'Deposit Waiver',
    name: 'Deposit Waiver',
    link: '/resources/deposit-waiver/',
    sort: 10
  },
  {
    icon: 'high-speed-internet',
    amenities: ['High-Speed Internet', 'High Speed Internet', 'High-Speed Internet (1GB)'],
    name: 'High-Speed Internet (1GB)',
    filterLabel: 'High-Speed Internet',
    filterKey: 'internet',
    link: '/high-speed-internet/',
    sort: 10
  },
  {
    icon: 'electric-car',
    amenities: ['EV Charging'],
    filterLabel: 'EV Charging',
    filterKey: 'ev',
    link: 'ev-chargers-available-for-your-garage/',
    sort: 10
  },
  {
    icon: 'car',
    amenities: ['Three Car Garage', 'Two Car Garage', 'One Car Garage', 'Garage'],
    filterLabel: 'Garage',
    sort: 20
  },
  {
    icon: 'fenced-yard',
    amenities: ['Fenced Yard'],
    filterLabel: 'Fenced Yard',
    filterKey: 'fenced_yard',
    sort: 20
  },
  {
    icon: 'car',
    amenities: ['Carport'],
    filterKey: 'carport',
    sort: 20
  },
  {
    icon: 'fan',
    amenities: ['Air Conditioner', 'A/C'],
    filterLabel: 'A/C',
    name: 'A/C',
    filterKey: 'ac',
    sort: 20
  },
  {
    icon: 'fire',
    amenities: ['Gas Furnace', 'Gas Range', 'Fireplace', 'Fire'],
    sort: 20
  },
  {
    icon: 'climatecontrol',
    amenities: ['Heat'],
    filterKey: 'heat',
    sort: 20
  },
  {
    icon: 'dryer',
    amenities: ['Washer and Dryer'],
    filterLabel: 'Laundry',
    link: '/resources/washer-dryer/',
    filterKey: 'washer_dryer_disclaimer',
    sort: 10
  },
  {
    icon: 'dryer',
    amenities: ['Washer & Dryer',
      'Washer and Dryer Included',
      'Washer/Dryer Included',
      'Dryer Included',
      'Washer Included',
      'Washer and Dryer'],
    filterLabel: 'Laundry',
    filterKey: 'washer_dryer',
    sort: 10
  },
  {
    icon: 'gated',
    amenities: ['Gated Community'],
    filterLabel: 'Gated Community',
    filterKey: 'gated_community',
    sort: 30
  },
  {
    icon: 'hanger',
    amenities: ['Large Closets', 'Oversized Closet'],
    filterLabel: 'Large Closets',
    filterKey: 'large_closets',
    sort: 30
  },
  {
    icon: 'pool',
    amenities: ['Pool'],
    filterLabel: 'Pool',
    filterKey: 'pool',
    sort: 30
  },
  {
    icon: 'pool',
    amenities: ['Spa/Hot Tub'],
    filterLabel: 'Spa/Hot Tub',
    filterKey: 'spa_hot_tub',
    sort: 30
  },
  {
    icon: 'pool',
    amenities: ['Community Pool'],
    filterLabel: 'Community Pool',
    filterKey: 'community_pool',
    sort: 30
  },
  {
    icon: 'refrigerator',
    amenities: ['Refrigerator'],
    filterLabel: 'Refrigerator',
    filterKey: 'refrigerator',
    sort: 30
  }
]

const amenitySetForAmenity = (amenity) => {
  const result = amenitiesWithIcons.find((amenityWithIcon) => {
    return amenityWithIcon.amenities.includes(amenity)
  })
  return Object.assign({}, result)
}

const amenitySetForFilterLabel = (filterLabel) => {
  return amenitiesWithIcons.find((amenityWithIcon) => {
    return 'filterLabel' in amenityWithIcon && amenityWithIcon.filterLabel === filterLabel
  })
}
const iconForAmenity = (amenity) => {
  const amenitySet = amenitySetForAmenity(amenity)
  if (typeof amenitySet !== 'undefined') {
    return amenitySet.icon
  }
  return null
}

const amenityFilters = (filters) => {
  if (Array.isArray(filters) && filters.length > 0) {
    return filters.filter(filter => {
      return filter.startsWith('amenity_')
    }).map(filter => {
      return filter.replace('amenity_', '')
    })
  }
  return []
}

function amenityFilterObjects (filters) {
  return amenityFilters(filters).map(amenity => {
    return amenitiesWithIcons.find(amenityWithIcon => {
      return amenityWithIcon.filterKey === amenity
    })
  })
}

const amenitiesMatchFilters = (amenities, filters, matchAll = true) => {
  const amenityFilterArray = amenityFilterObjects(filters)
  if (amenityFilterArray.length > 0 && amenities.length === 0) {
    console.log('amenityFilterArray no amenities', amenityFilterArray, 'amenities', amenities)
    return false
  }
  if (amenityFilterArray.length === 0 && amenities.length === 0) {
    return true
  }
  if (matchAll) {
    for (const amenityFilter of amenityFilterArray) {
      console.log('amenityFilter', amenityFilter.amenities, amenities, arrayContainsAny(amenityFilter.amenities, amenities))
      if (!arrayContainsAny(amenityFilter.amenities, amenities)) {
        return false
      }
    }
    return true
  } else {
    for (const amenityFilter of amenityFilterArray) {
      if (arrayContainsAny(amenityFilter.amenities, amenities)) {
        return true
      }
    }
  }

  return false
}

export { iconForAmenity, amenitySetForAmenity, amenitySetForFilterLabel, amenitiesWithIcons, amenityFilters, amenitiesMatchFilters, amenityFilterObjects }
